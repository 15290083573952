.btn-primary{
    --bs-btn-bg: var(--primary-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-color);
    --bs-btn-hover-border-color: var(--primary-color);
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-color);
    --bs-btn-active-border-color: var(--primary-color);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary-color);
    --bs-btn-disabled-border-color: var(--primary-color);
    position: relative;
    z-index: 1;
    &::after{
        content: '';
        width: 0px;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.3s all linear;
        border-radius: 7px;
        z-index: -1;
    }
    &:hover{
        &::after{
            width: 100%;
        }
    }
}