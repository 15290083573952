.navbar{
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    box-shadow: 0px 20px 30px rgba(167, 167, 167, 0.1);
    z-index: 99;
    padding: 20px;
    .navbar-brand{
        img{
            width: 100px;
        }
    }
}