body{
    overflow-x: hidden;
    &::-webkit-scrollbar {
        position: absolute;
        width: 8px;
        margin-left: -10px;
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(156,152,152,0.5);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
}
// home section
.home-banner-wrapper{
    min-height: 619px;
    padding-top: 168px;
    background-color: #F5F5F5;
    position: relative;
    overflow: hidden;
    z-index: 1;
    h1{
        font-size: 58px;
        max-width: 566px;
        margin-bottom: 37px;
    }
    p{
        font-size: 20px;
        margin-bottom: 30px;
    }
    .second-para{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .btn{
        padding: 10px 22px;
        border-radius: 8px;
    }
    .animation-ball{
        width: 700px;
        height: 700px;
        border-radius: 50%;
        background-color: var(--primary-opac-color);
        position: absolute;
        top: 72px;
        left: -80px;
        z-index: -1;
    }
    .banner-img{
        position: absolute;
        bottom: -58px;
        right: 0;
        max-width: 450px;
    }
    @media only screen and (min-width: 1500px) {
        min-height: 677px;
        .banner-img {
            bottom: -76px;
            max-width: 600px;
        }
    }
    @media only screen and (min-width: 1700px) {
        min-height: 722px;
        .banner-img {
            bottom: -76px;
            max-width: 600px;
        }
    }
    @media only screen and (max-width: 1024px) {
        .banner-img{
            display: none;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 20px;
        padding-top: 150px;
        h1{
            font-size: 31px;
        }
        .animation-ball{
            width: 450px;
            height: 450px;
            top: 117px;
            left: -88px;
            z-index: -1;
        }
    }
}

// service section
.service-main-wrapper{
    padding: 100px 0;
    text-align: center;
    @media only screen and (max-width: 767px) {
        overflow: hidden;
    }
    .page-title{
        font-size: 40px;
    }
    .card-wrapper{
        // display: flex;
        // justify-content: space-between;
        margin-top: 30px;
        .slick-track{
            display: flex;
            padding: 100px 0;
            margin-left: -161px;
            @media only screen and (max-width: 1024px) {
                margin-left: 0;
            }
            @media only screen and (max-width: 900px) {
                margin-left: -137px;
            }
            @media only screen and (max-width: 767px) {
                padding: 20px 0;
                margin-left: -10px;
            }
        }
        .slick-slide {
            padding: 0 10px;
            div:first-of-type, .card-item{
                height: 100%;
            }
        }
        .slick-center {
            margin: 0 43px;
            @media only screen and (max-width: 767px) {
                margin: 0 20px;
            }
        }
        .slick-prev:before, .slick-next:before{
            color: var(--primary-color);
            font-size: 42px;
        }
        .slick-prev {
            left: -45px;
            @media only screen and (max-width: 767px) {
                left: -7px;
            }
        }
        .slick-next{
            @media only screen and (max-width: 767px) {
                right: 14px;
            }
        }
        .card-item{
            border: 1px solid #ccc;
            padding: 10px;
            flex: 1;
            width: 20%;
            margin: 10px;
            border-radius: 6px;
            transition: 0.3s all linear;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            min-height: 330px;
            @media only screen and (min-width: 1500px) {
                min-height: 310px;
            }
            @media only screen and (max-width: 767) {
                min-height: auto;
            }
            &:hover{
                background-color: var(--primary-opac-color);
                border-color: var(--primary-color);
                // transform: scale(1.1);
            }
        }
        .slick-center .card-item {
            @media only screen and (min-width: 768px) {
                transform: scale(1.3);
                background-color: #fff;
                border-color: var(--primary-color);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                &:hover{
                    background-color: #fff;
                }
            }
        }
        @media only screen and (max-width: 1200px) {
            flex-wrap: wrap;
            .card-item{
                min-width: 28.33%;
            }
        }
        @media only screen and (max-width: 767px) {
            .card-item{
                min-width: 85%;
            }
        }
        .service-img{
            max-width: 100px;
        }
        h3{
            font-size: 20px;
            margin-top: 23px;
            margin-bottom: 12px;
        }
        p{
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 20px 0;
        .page-title{
            font-size: 30px;
        }
        .card-wrapper{
            margin-top: 10px;
        }
    }
}

// abou us section
.about-us-wrapper{
    padding: 100px 0;
    h3{
        font-size: 40px;
    }
    .sub-title{
        font-size: 20px;
        margin-top: 17px;
    }
    .aboutUsImage{
        border-radius: 20px;
    }
    .about-right-content{
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 25px;
        border-radius: 20px;
        // margin-left: -19px;
        position: relative;
        // margin-top: -73px;
        .anime-bubble{
            position: absolute;
            top: -70px;
            right: -70px;
            width: 130%;
            height: 119%;
            background-color: var(--primary-opac-color);
            border-radius: 56% 14% 41% 20%;
            z-index: -1;
            animation: animeBubble 5s infinite linear;
        }
    }
    .mission-box{
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        h6{
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 2px;
        }
        .mission-img{
            max-width: 60px;
        }
        .mission-content{
            margin-left: 19px;
            p{
                margin-bottom: 0;
            }
        }
    }
    @media only screen and (max-width: 1100px) {
        overflow: hidden;
    }
    @media only screen and (max-width:  1023px) {
        position: relative;
        .row{
            flex-direction: column-reverse;
            .col-md-6{
                width: 100%;
            }
        }
        .aboutUsImage{
            width: 100%;
            position: absolute;
            bottom: 0;
            opacity: 0.18;
            left: 0;
        }
        .about-right-content{
            padding: 0;
            padding-top: 25px;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 40px 15px;
        .about-right-content{
            padding-top: 0;
        }
        h3{
            font-size: 25px;
            margin-top: 20px;        
        }
    }
}

// delivery process
.delivery-process-wrap{
    background-color: #000;
    color: #fff;
    position: relative;
    z-index: 1;
    .process-box-container{
        margin: 50px 0;
    }
    .left-content{
        margin-top: 40px;
    }
    .snow-rotate-img{
        max-width: 99px;
        margin-bottom: 99px;
        margin-top: 22px;    
        animation: rotateImg 12s infinite linear;
    }
    .left-boxes{
        margin-bottom: 25px;
        h6{
            border-bottom: 3px solid var(--primary-color);
            display: inline-block;
            margin-bottom: 5px;
            padding-bottom: 2px;
        }
        p{
            font-size: 13px;
            color: #ccc;
        }
    }
    .intro-banner{
        position: absolute;
        transform: scaleX(-1);
        right: 0;
        z-index: -1;
        bottom: 0;
        max-width: 40%;
        opacity: 0.3;
    }
    .process-box{
        width: 40%;
        flex: auto;
        border: 1px solid #cccc;
        padding: 20px;
        margin: 10px;
        border-radius: 11px;
        display: flex;
        background-color: #000;
        position: relative;
        z-index: 1;
        overflow: hidden;
        &::after{
            content: '';
            width: 150px;
            height: 150px;
            border-radius: 50%;
            position: absolute;
            left: -115px;
            bottom: -136px;
            z-index: -1;
            transition: 0.3s all linear;
            background-color: var(--primary-color);
        }
        &:hover{
            &::after{
                left: -89px;
                bottom: -96px;
            }
        }
    }
    .process-count{
        margin-right: 30px;
    }
    .process-content{
        h5{
            font-size: 16px;
        }
        p{
            font-size: 13px;
            color: #999696;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width:  1023px) {
        .row{
            flex-direction: column;
        }
        .left-content{
            padding: 0 8px;
        }
        .process-box-container{
            // flex-wrap: nowrap;
            // overflow-x: auto;
            margin: 0;
            margin-top: 30px;
            .process-box{
                min-width: 40%;
            }
        }
        .snow-rotate-img {
            max-width: 68px;
            margin-bottom: 37px;
            margin-top: 0px;
        }
        .intro-banner{
            max-width: none;
            width: 100%;
            opacity: 0.3;
            max-width: 500px;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 40px 5px;
        .process-box-container .process-box{
            min-width: 78%;
            margin: 0;
        }
        .slick-next {
            right: -7px;
        }
        .slick-prev {
            z-index: 99;
            left: -9px;
        }
    }
}
.ai-service-wrap{
    .slick-slide {
        display: block;
        margin: 0 10px;
        @media only screen and (max-width: 767px) {
            margin: 0;
        }
    }
    .slick-track{
        display: flex;
    }
    .process-box{
        min-height: 193px;
        display: flex !important;
    }
}
@keyframes animeBubble {
    0%{
        border-radius: 56% 14% 41% 20%;
    }
    35%{
        border-radius: 45% 106% 47% 129%;
    }
    70%{
        border-radius: 45% 83% 25% 38%;
    }
    100%{
        border-radius: 56% 14% 41% 20%;
    }
}

@keyframes rotateImg {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

// we-special-wrapper
.we-special-wrapper{
    background-color: #000;
    color: #fff;
    position: relative;
    z-index: 1;
    text-align: center;
    .special-box-container{
        display: flex;
        margin-top: 57px;
        justify-content: center;
        text-align: left;
    }
    .special-box{
        max-width: 260px;
        border: 1px solid #ccc;
        padding: 20px;
        padding-top: 129px;
        margin-right: 25px;
        border-radius: 9px;
        position: relative;
        aspect-ratio: 2/2.4;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        p{
            margin-bottom: 0;
            font-size: 13px;
            color: #ccc;
        }
        &:hover{
            .special-b-icon{
                transform: scaleX(-1);
            }
        }
    }
    .special-icon-wrap{
        background-color: var(--primary-color);
        padding: 16px;
        width: 100px;
        height: 100px;
        border: 10px solid rgba(0,0,0,0.2);
        border-radius: 50%;
        border-top-right-radius: 0;
        position: absolute;
        right: -1px;
        top: -2px;
        .special-b-icon{
            width: 100%;
            transition: 0.3s all linear;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 15px;
        padding-bottom: 10px;
        .special-box-container{
            flex-direction: column;
            margin-top: 40px;
        }
        .special-box{
            width: 100%;
            margin-right: 0;
            max-width: none;
            aspect-ratio: 2/1.8;
            margin-bottom: 20px;
        }
    }
}

// footers wrapper
.home-footer-wrap{
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.88);
    text-align: center;
    .channels-icons{
        margin: 0 8px;
        transition: 0.3s all linear;
        display: inline-block;
        &:hover{
            transform: translateY(-5px);
        }
    }
}